import { createServer } from "miragejs";
import appConfig from "configs/app.config";

import { usersData, userDetailData } from "./data/usersData";
import {
  productsData,
  ordersData,
  orderDetailsData,
  salesDashboardData,
} from "./data/salesData";

import {
  settingData,
  settingIntergrationData,
  settingBillingData,
  invoiceData,
  logData,
  accountFormData,
} from "./data/accountData";

const { apiPrefix } = appConfig;

export default function mockServer({ environment = "test" }) {
  return createServer({
    environment,
    seeds(server) {
      server.db.loadData({
        usersData,
        userDetailData,

        orderDetailsData,
        settingData,
        settingIntergrationData,
        settingBillingData,
        invoiceData,
        logData,
        accountFormData,

        salesDashboardData,
      });
    },
    routes() {
      this.urlPrefix = "";
      this.namespace = "";
      this.passthrough((request) => {
        let isExternal = request.url.startsWith("http");
        return isExternal;
      });
      this.passthrough();
    },
  });
}
