const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/business-Select",
  secondaryAuthenticatedEntryPath: "/dashboard-page",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
};

export default appConfig;
