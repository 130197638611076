import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import axios from "axios";

// API service functions
const url = process.env.REACT_APP_API_URL;

const apiSignIn = async (values) => {
  return await axios.post(`${url}/UsersAuth/login`, values);
};

const apiSignUp = async (values) => {
  return await axios.post(`${url}/UsersAuth/register`, values);
};

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const handleAuthSuccess = (token, user) => {
    dispatch(onSignInSuccess(token));
    dispatch(
      setUser(
        user || {
          avatar: "",
          userName: "Anonymous",
          authority: ["USER"],
          email: "",
        }
      )
    );

    const redirectUrl = query.get(REDIRECT_URL_KEY);
    const targetPath = user.isFromPrimaryBusiness
      ? appConfig.authenticatedEntryPath
      : appConfig.secondaryAuthenticatedEntryPath;

    if (redirectUrl && redirectUrl !== "/sign-in") {
      navigate(redirectUrl);
    } else {
      navigate(targetPath);
    }
  };

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        handleAuthSuccess(resp.data.token, resp.data.user);
        return { status: "success", message: "" };
      }
    } catch (errors) {
      return { status: "failed", message: "Incorrect Username or Password" };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        handleAuthSuccess(resp.data.token, resp.data.user);
        return { status: "success", message: "" };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleLogOut = () => {
    localStorage.removeItem("user");
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate("/sign-in");
    window.location.reload(true);
  };

  const signOut = async () => {
    handleLogOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
